@import url('https://fonts.googleapis.com/css2?family=Poor+Story&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One&display=swap');

:root{
  --pepegagreen: rgb(87, 160, 35);
  --pepegatextgreen: rgb(129, 215, 66);
  --pepegagray: rgb(40, 40, 40);
}

body {
  background-color: var(--pepegagray);
  overflow-x: hidden;
  background-image: null;
}

.custom-back {
  border-radius: 10px;
  margin-left: -7%;
  margin-right: -7%;
  padding-bottom: 50px;
  padding-top: 10px;
  margin-top: -6px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--pepegagreen);
  text-align: center;
}

ul a{
  color: rgb(189, 0, 0);
  text-decoration: none;
}

ul{
  padding: 0;
  list-style-type: none;
  text-align: center;
  margin: auto;
}

footer ul li{
  font-size: 25px;
  display: block;
  padding: 20px;
}

footer ul li:hover{
  color: blue;
  background-color: rgb(72, 131, 29);
}

.wrapper {
  width: 70%;
  margin: auto;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(255, 255, 255, 0.411);
  border-radius: 5px;
  margin-bottom: 300px;
  backdrop-filter: blur(18px);

  /* ANIMATION */
  position: relative;
  animation-name: summons;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

.wrapper img{
  border: 3px solid black;
  border-radius: 10px;
}

.grido-mode div:nth-of-type(1) img{
  width: 30%;
}

.grido-mode div:nth-of-type(3) img{
  width: 40%;
}

.wrapper > h1{
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}

button {
  border-radius: 30px;

  /* TRANSITION */
  transition-property: all;
  transition-duration: 1s;
}

button:hover{
  cursor: pointer;
  transform: scale(1.3, 1.3);
}

.wrapper p {
  font-family: 'Fuzzy Bubbles';
}

.wrapper h1, .wrapper h2, .wrapper h3, .wrapper h4, .wrapper ol{
  font-family: 'Rubik';
}

#main-cont h1, #main-cont small, .wrapper button{
  font-family: 'Teko';
}

.wrapper button {
  font-weight: bold;
}

/* CUSTOM INFO */
#prestigious {
  background-color: rgba(0, 255, 191, 0.411);
  padding-bottom: 10px;
}

#prestigious .grido-mode div > img {
  background-color: rgb(150, 0, 0);
}

#prestigious button{
  color: black;
  background-color: rgba(0, 255, 191, 0.411);
}

#prestigious button:hover{
  background-color: white;
}

#ashwx20 button{
  color: white;
  background-color: rgba(255, 94, 0, 0.711);
}

#ashwx20 button:hover{
  color: black;
  background-color: white;
}

#moistcritikal button {
  background-color: rgba(98, 47, 173, 0.411);
  color: white;
}

#moistcritikal button:hover {
  background-color: white;
  color: black;
}

#kuruhotshots button{
  background-color: rgba(87, 160, 35, 0.411);
}

#kuruhotshots button:hover{
  background-color: white;
}

#wirtual button{
  background-color: rgba(154, 203, 255, 0.711);
}

#wirtual button:hover{
  background-color: white;
}

#ashwx20 {
  background-color: rgba(255, 94, 0, 0.711);
  color: white;
}

#ashwx20 .grido-mode div > img  {
  background-color: rgba(202, 199, 0);
}

#moistcritikal{
  background-color: rgba(98, 47, 173, 0.411);
  color: white;
}

#moistcritikal .grido-mode div > img {
  background-color: white;
}

#kuruhotshots{
  background-color: rgba(87, 160, 35, 0.411);
}

#kuruhotshots .grido-mode div > img {
  background-color: black;
}

#wirtual{
  background-color: rgba(154, 203, 255, 0.711);
}

#wirtual .grido-mode div > img {
  background-color:rgb(150, 0, 0);
}

#arez{
  background-color: rgba(85, 85, 85, 0.811);
  color: rgb(0, 255, 0);
}

#arez .grido-mode div > img {
  background-color: rgb(85, 170, 255);
}

#arez button{
  background-color: rgba(85, 85, 85, 0.811);
  color: red;
}

#arez button:hover{
  background-color: white;
  color: black;
}

#dEden, #dEden button{
  background-color: rgba(165, 140, 0, 0.711);
}

#dEden button:hover{
  background-color: white;
}
/* ---- */

#main-cont small{
  font-weight: normal;
}

#main-cont ul li{
  font-family: 'Passion One';
}

#sorce, #abut{
  background-color: rgb(255, 255, 255, 0.311);
}

#sorce {
  overflow-x: auto;
}

table{
  width: 93%;
  margin: auto;
  margin-bottom: 30px;
  border-collapse: collapse;
}

#sorce table tr td, #sorce table tr th{
  border: 2px solid black;
  padding: 8px;
}

#sorce table tr td{
  width: 100px;
}

#sorce table tr td{
  font-family: 'Fuzzy Bubbles';
}

#sorce table tr th{
  font-family: 'Passion One';
  font-weight: normal;
  font-size: 25px;
}

#pepega-warrant-form input{
  width: 30%;
  height: 30px;
}

#Spicy-Warrants input[type = "Checkbox"]{
  margin-right: 20px;
}

#pepega-warrant-form button, #Spicy-Warrants button{
  height: 40px;
  width: 50%;
  margin-top: 30px;
  margin-left: 50px;
  font-size: 26px;
  background-color: rgb(121, 226, 0);
}

.themFilters button{
  background-color: rgb(121, 226, 0) !important;
  width: 15%;
  margin: auto;
  margin-right: 5px;
}

#pepega-warrant-form label{
  font-family: 'Fuzzy Bubbles';
}

#Spicy-Warrants li{
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 32px;
  font-family: 'Passion One';
}

.pepega-wrnt-form-back, .back-pepega-warrants, .themFilters{
  background-color: black;
  color: rgb(37, 158, 0);
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
}

.themFilters{
  margin-top: -5px;
  margin-bottom: -5px;
}

.pepega-wrnt-form-back{
  border-radius: 5px 5px 0 0;
}

.back-pepega-warrants{
  border-radius: 0 0 5px 5px;
}

.pepega-wrnt-form-back h1, .back-pepega-warrants h1{
  font-size: 40px;
}

#pepega-warrant-form button:hover, #Spicy-Warrants button:hover, .themFilters button:hover{
  background-color: rgb(86, 160, 1);
}

.themFilters button:hover{
  transform: scale(1.1, 1.1);
}

#show-head{
  margin-top: -10px;
}

@keyframes summons{
  0%{
    opacity: 0;
    top: 30px;
  }
  100%{
    opacity: 1;
    top: 0px;
  }
}

footer h1{
  margin-top: 0px;
}

.rating img{
  width: 20px;
  height: 20px;
  border: none;
}

.prem-sub{
  background-color: gold !important;
  color: black;
  position: relative;
  top: -5px;
  width: 80px;
  border-radius: 100px;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 5px solid black;
  
  transition-property: none;
  animation-name: golden-hover;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.prem-sub:hover{
  transform: scale(1, 1);
}

@keyframes golden-hover{
  0%{
    color: black ;
  }
  50%{
    color: rgb(0, 38, 255);
  }
  100%{
    color: rgb(255, 0, 0);
  }
}

@media (min-width: 1000px){
  footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    position: fixed;
    padding: 10px;
  }

  .wrapper {
    padding-bottom: 3px;
    margin-bottom: 113px;
  }

  #prestigious {
    padding-bottom: 15px;
  }

  footer ul li{
    text-align: center;
    padding: 10px;
  }

  footer ul{
    text-align: right;
    margin-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }

  .grido-mode{
    display: grid;
    grid-template-columns: 200px 1fr 300px;
  }

  .grido-mode div:nth-of-type(3) img{
    width: 90%;
  }

  .grido-mode div:nth-of-type(1) img{
    width: 80%;
  }

  #pepega-warrant-form button{
    margin-left: 20px;
    margin-top: 0;
    width: 200px;
  }

  .custom-back{
    padding-bottom: 1px;
  }

}